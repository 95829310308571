<template>
  <span class="alTableBtnsComponents">
    <el-button
      type="text"
      v-if="JSON.stringify(outsideBtns) != '{}'"
      @click="handleBtnClick(outsideBtns)"
      :disabled="!outsideBtns.notDisabled"
      >{{ outsideBtns.text }}</el-button
    >

    <el-popover
      placement="bottom-end"
      trigger="click"
      popper-class="alTableBtnsComponentsPopover"
      v-model="visible"
      v-if="innerBtns.length"
    >
      <div style="text-align: right; margin: 0">
        <el-button
          v-for="(item, index) in innerBtns"
          :key="index"
          size="mini"
          type="text"
          @click="handleBtnClick(item)"
          :disabled="!item.notDisabled"
          class="operate-item"
          >{{ item.text }}</el-button
        >
      </div>
      <i class="el-icon-more moreIcon" slot="reference"></i>
    </el-popover>
  </span>
</template>

<script>
export default {
  props: {
    btnsList: {
      type: Array,
    },
    btnsIndex: {
      type: Number,
    },
    rowData: {
      type: Object,
    },
  },
  data() {
    return {
      innerBtns: [],
      outsideBtns: {},
      visible: false,
    };
  },
  mounted() {
    this.confirmBtnLocation();
  },
  methods: {
    confirmBtnLocation() {
      let buttons = this.btnsList.filter((item) => {
        if (item.auth) {
          return this.inspectButton(item.auth);
        } else {
          return true;
        }
      });
      if (buttons.length == 0) {
        this.$emit("noButton");
      }
      this.outsideBtns = buttons[0];
      this.innerBtns = buttons.slice(1);
    },
    handleBtnClick(item) {
      let event = item.event;
      if (this.rowData) {
        if (item.otherParams) {
          this.$parent.$parent.$parent[event](this.rowData, item.otherParams);
        } else {
          this.$parent.$parent.$parent[event](this.rowData);
        }
      } else {
        if (item.otherParams) {
          this.$parent.$parent.$parent[event](this.btnsIndex, item.otherParams);
        } else {
          this.$parent.$parent.$parent[event](this.btnsIndex);
        }
      }

      this.visible = false;
    },
  },
  watch: {
    btnsList: {
      deep: true,
      handler() {
        this.confirmBtnLocation();
      },
    },
  },
};
</script>

<style scoped lang="less">
.alTableBtnsComponents {
  .moreIcon {
    width: 13px;
    height: 12px;
    color: #1374f2;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    transform: rotate(90deg);
    position: relative;
    top: 1px;
    margin-left: 12px;
  }
}
</style>
<style lang="less">
.alTableBtnsComponentsPopover {
  margin-top: 10px;
  padding: 0;
  min-width: 100px;

  .popper__arrow {
    display: none;
  }

  .operate-item {
    display: block;
    border: none;
    background-color: transparent;
    color: #4e545f;
    text-align: left;
    width: 104px;
    height: 32px;
    padding: 0 10px;
    line-height: 32px;
    margin-left: 0 !important;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #1374f2;
      background-color: #ecf5fd;
    }
    &.is-disabled {
      color: #b3b3b3;
      cursor: not-allowed;
      background-image: none;
      background-color: #f7f7f7;
      border-color: #e3e3e3;
    }
  }
}
</style>