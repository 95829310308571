<template>
  <div>
    <div class="search">
      <searchPosition @searchChange="searchChange"></searchPosition>
    </div>
    <!-- v-if="inspectButton('tac:jom:add')" -->
    <div class="clearfix public">
      <el-button
        class="fl"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="publichChange"
        >{{ $t("PostJob") }}</el-button
      >
    </div>
    <div class="tableBlock tableData">
      <el-table
        :data="tableData"
        style="width: 100%; height: 550px"
        :tableLoading="loading"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column :label="$t('jobInfo')">
          <template slot-scope="scope">
            <div class="clearfix">
              <div class="fl mainColor">
                {{ scope.row.name | textFormat }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('salaryPack')">
          <template slot-scope="scope">
            <div v-if="scope.row.salary_lowest == 0">
              <span>{{ $t("negotiable") }}</span>
            </div>
            <div v-else>
              <span>{{ scope.row.salary_lowest | textFormat }}K</span>
              <span>-</span>
              <span>{{ scope.row.salary_highest | textFormat }}K</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('jobAdress')">
          <template slot-scope="scope">
            <span>{{
              scope.row.country
                | countryCityFormat(
                  scope.row.city_name_en,
                  scope.row.city_name_zh,
                  LOCALE
                )
                | textFormat
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('workExperience')">
          <template slot-scope="scope">
            <span>{{
              scope.row.experience | experienceFormat(LOCALE) | textFormat
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('educatMents')" width="200px">
          <template slot-scope="scope">
            <span>{{
              scope.row.level_require | levelRequireFormat(LOCALE)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('publishTime')">
          <template slot-scope="scope">
            <span>{{ scope.row.created_time | secondFormat("LL") }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('auditStaus')">
          <template slot-scope="scope">
            <span v-show="scope.row.status == 0">{{ $t("RegisterWait") }}</span>
            <span v-show="scope.row.status == 1">{{
              $t("RegisterWaitpass")
            }}</span>
            <span v-show="scope.row.status == 2"
              >{{ $t("RegisterRefuse") }}
              <el-popover
                placement="top"
                :title="$t('receive1V1Content')"
                width="200"
                trigger="click"
                :content="scope.row.reason"
              >
                <i class="el-icon-question cursor" slot="reference"></i>
              </el-popover>
            </span>
            <span v-show="scope.row.status == 3">{{ $t("release") }}</span>
            <span v-show="scope.row.status == 4">{{
              $t("closed")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('delivered')">
          <template slot-scope="scope">
            <span
              style="color: #409eff; cursor: pointer"
              @click="gotoList(scope.row)"
              >{{ scope.row.delivery_count }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="address" :label="$t('handle')" width="200">
          <template slot-scope="scope">
            <alTableBtns
              :btnsList="scope.row.btnsList"
              :btnsIndex="scope.row.$index"
              :rowData="scope.row"
            ></alTableBtns>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page.sync="start"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
    <el-drawer
      :title="drawerTitle"
      size="750px"
      :visible.sync="drawerShow"
      direction="rtl"
      :wrapperClosable="false"
      @close="emptyPositionId"
    >
      <div style="padding: 0 24px">
        <addPosition
          @success="successChange"
          @cencel="cencelDrawer"
          ref="addPosition"
          :positionId="positionId"
        ></addPosition>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import searchPosition from "~bac/components/talentCenter/position/searchPosition";
import addPosition from "~bac/components/talentCenter/position/addPosition";
import alTableBtns from "~/baseComponents/alTableBtns";
import { SSstore } from "~/baseUtils/storage";
export default {
  layout: "memberCenter",
  components: {
    searchPosition,
    addPosition,
    alTableBtns,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      tableData: [],
      drawerTitle: this.$t("PostJob"),
      drawerShow: false,
      total: 0,
      limit: 10,
      start: 1,
      tableHeight: 100,
      positionId: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    gotoList(item) {
      SSstore.set("positionID", item.pisition_id);
      this.$router.push({
        path: "/console/talentCenter/positionResume",
        query: {
          //  id: item.pisition_id,
        },
      });
    },
    async getList(val) {
      console.log(this.IS_EN)
      this.loading = true;
      let params = {
        company_id: this.USER_INFO.company_id,
        limit: this.limit,
        source: this.PJSource,
        start: this.start - 1,
      };
      let _params = Object.assign(params, val);
      let data = await this.$store.dispatch(
        "baseConsole/job_positionList",
        _params
      );
      if (data.success) {
        this.loading = false;
        this.total = data.count;
        data.data.forEach((it) => {
          it.btnsList = [
            {
              text: this.$t("edit"),
              event: "editPosition",
              notDisabled: it.status != 4,
              auth: "",
            },
            {
              text: this.$t("publish"),
              event: "closePosition",
              notDisabled: it.status !== 0 && it.status !== 2 && it.status == 4,
              auth: "",
            },
            {
              text: this.$t("close"),
              event: "closePosition",
              notDisabled: it.status !== 0 && it.status !== 2 && it.status != 4,
              auth: "",
            },
          ];
        });
        this.tableData = data.data;
      } else {
        this.loading = false;
      }
    },
    publichChange() {
      this.drawerTitle = this.$t("PostJob");
      this.drawerShow = true;

      this.$nextTick((e) => {
        this.$refs.addPosition.clearData();
        this.positionId = null;
      });
    },
    editPosition(val) {
      this.drawerTitle = this.$t("editJob");
      this.drawerShow = true;
      this.$nextTick((e) => {
        this.positionId = val.pisition_id;
      });
    },
    emptyPositionId() {
      this.positionId = null;
    },
    cencelDrawer() {
      this.drawerShow = false;
      this.$refs.addPosition.clearData();
    },
    successChange() {
      this.drawerShow = false;
      this.getList();
      this.$refs.addPosition.clearData();
    },
    async closePosition(val) {
      let status = "";
      if (val.status == 4) {
        status = 3;
      } else {
        status = 4;
      }
      let params = {
        position_id: val.pisition_id,
        status: status,
        source: this.PJSource,
        edit_type: 1,
      };
      let data = await this.$store.dispatch(
        "baseConsole/job_positionSave",
        params
      );
      if (data.success) {
        if (status == 4) {
          this.$message.success(this.$t('closed'));
        } else {
          this.$message.success(this.$t('opend'));
        }
        this.getList();
      }
    },
    searchChange(val) {
      this.start = 1;
      this.getList(val);
    },
    handlePageChange(e) {
      this.start = e;
      this.getList();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == 4) {
        return "back";
      }
    },
    // idCodeChange(){
    //    this.$refs.idCode.isIdCodeDialog();
    // },
  },
};
</script>
<style lang="less" scoped>
.input {
  width: 250px;
}
.tableData {
  .label {
    border: 1px solid #c8cdd0;
    border-radius: 10px;
    line-height: 20px;
    margin-left: 20px;
    font-size: 12px;
    padding: 0 15px;
    min-width: 104px;
    text-align: center;
  }
  .salary {
    span {
      color: #ff2b0d;
    }
    margin-right: 16px;
  }
  .info {
    span {
      color: #666666;
    }
  }
  .publicDate {
    margin-right: 50px;
    font-size: 12px;
    color: #999999;
  }
}
.top {
  margin-top: 3px;
}
.public {
  margin: 5px 12px 10px;
}
.division {
  margin: 0 3px;
}
.delivery {
  cursor: pointer;
  color: #0083f6 !important;
}
.search {
  padding: 12px;
}
/deep/ .el-table .back {
  background: rgb(245, 247, 250);
}
/deep/.el-table td,
.el-table th {
  padding: 11px 0;
}
/deep/.el-drawer__body {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
