<template>
  <!-- 职位管理 -->
  <div class="positionDetail"  v-loading="loading">
    <!-- <div class="clearfix">
      <div class="preview fr">
        <el-button icon="el-icon-view" type="primary" plain size="small" @click="previewShow=true">预览职位</el-button>
      </div>
    </div> -->
    <div class="jobInfo">
      <el-form ref="form" :model="form" label-width="180px" label-position="top" size="small" :rules="fromRules">
        <div class="infoTitle">{{$t('infoBasic')}}</div>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('company')">
              <el-input class="input" disabled :value="
                  USER_INFO.company_name_en | priorFormat(USER_INFO.company_name_zh,LOCALE)
                "></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('workNature')" prop="position_kind">
              <el-radio-group style="width: 294px" v-model="form.position_kind">
                <el-radio label="1">{{$t('fullTime')}}</el-radio>
                <el-radio label="2">{{$t('partTime')}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('jobName')" prop="name">
              <el-input class="input" v-model="form.name" :placeholder="$t('pleaseinputposition')" maxlength="15"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('recruitmentType')">
              <el-select class="input" v-model="form.recruit_type" :placeholder="$t('pleaseSelect')">
                <el-option v-for="item in recruitList" :key="item.value" :label="  item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="clearfix">
          <el-form-item :label="$t('jobAdress')" prop="country" class="fl">
            <CountrySelect style="width: 142px; margin-right: 15px" class="fl" :placeTitle="$t('country')" v-model="form.country"
              :locale="LOCALE"></CountrySelect>
          </el-form-item>
           <el-form-item label=" " :prop="'city_name_' + LOCALE" class="fl" style="margin-top:30px"><!--['region_'+LOCALE]-->
            <CityComplete class="fl" style="width: 143px" v-model="form['city_name_' + LOCALE]" :locale="LOCALE" :placeTitle="$t('city')"
              :countryId="form.country"></CityComplete>
          </el-form-item>
        
        </div>
            <el-form-item prop="address">
              <el-input v-model="form.address" size="small" :placeholder="$t('addressDetaild')"  style="width: 650px;margin: -10px 0 0px"></el-input>
            </el-form-item>
        <el-row style="margin-top:20px">
          <el-form-item :label="$t('presentationVideo')">
            <el-col :span="11">
              <el-select class="input" v-model="form.video_id" :placeholder="$t('relatedJobVideo')" clearable filterable>
                <el-option v-for="(item,index) in videoList" :key="index" :value="item.id" :label="item.name">
                  <div class="clearfix ">
                    <el-image :src="item.cover_url?item.cover_url:DEFAULT_LOGO" class="videoImg fl"></el-image>
                    <div class="fl textOverflow" style="width:215px" :title="item.name">{{item.name}}</div>
                  </div>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <div class="fontColor videoPage" @click="toCompanyVideo">
                {{$t('notFindVideo')}}，{{$t('meetingEnter')}}
                <span class="mainColor1 cursor">{{$t('companyVideo')}} </span>{{$t('add')}}
              </div>
            </el-col>
          </el-form-item>
        </el-row>
        <div class="line"></div>
        <div class="infoTitle">{{$t('jobRequirements')}}</div>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('workExperience')">
              <el-select class="input" v-model="form.experience" :placeholder="$t('workExperience')">
                <el-option v-for="item in experienceList" :key="item.value" :label="item.label_en | priorFormat(item.label_zh,LOCALE)" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('educatMents')" prop="level_require">
              <el-select class="input" v-model="form.level_require" :placeholder="$t('educatMents')">
                <el-option v-for="item in educationList" :key="item.value"  :label="item.label_en | priorFormat(item.label_zh,LOCALE)" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="clearfix">
              <el-form-item :label="$t('salaryRange')" prop="salary_lowest" class="fl">
                <el-select class="input" style="width: 136px" v-model="form.salary_lowest" :placeholder="$t('selection_range')">
                  <el-option v-for="item in wagesList" :key="item.value"  :label="item.label_en | priorFormat(item.label_zh,LOCALE)" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <span class="fl" style="margin: 55px 8px 0" v-if="form.salary_lowest!=0">{{$t('to')}}</span>
              <el-form-item  v-if="form.salary_lowest!=0" prop="salary_highest" label=" " class="fl" style="margin-top: 30px">
                <el-select class="input fl" style="width: 136px" v-model="form.salary_highest" :placeholder="$t('selection_range')">
                  <el-option v-for="item in wagesList" :key="item.value"  :disabled="item.value==0"  :label="item.label_en | priorFormat(item.label_zh,LOCALE)" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('positionType')" prop="position_type">
              <el-select class="input" v-model="form.position_type" :placeholder="$t('positionType')">
                <el-option v-for="item in recruitTypeList" :key="item.value" :label="item.label_en | priorFormat(item.label_zh,LOCALE)" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="desc">
          <div slot="label">{{$t('jobDescrip')}}
            <span class="fontColor" style="font-size:12px">{{$t('pleaseNotInfoTips')}}
              <el-button type="text" class="mainColor1 cursor" v-if="!IS_EN" @click="publichShow=true">{{$t('Job_release_specification')}}</el-button>
              <el-button type="text" class="mainColor1 " v-else disabled> {{$t('Job_release_specification')}}</el-button>
            </span>
          </div>
          <el-input style="width: 650px;" type="textarea" :rows="16" :placeholder="LOCALE=='zh'?defaultHolder:' '" v-model="form.desc"
            maxlength="5000" resize="none" show-word-limit>
          </el-input>
        </el-form-item>
        <div class="line" style="margin-top:20px"></div>
        <div class="infoTitle">{{$t('jobContact')}}</div>
        <el-form-item :label="$t('name')" prop="contact.name">
          <el-input class="input" size="small" v-model="form.contact.name" :placeholder="$t('pleasecontactPerson')"></el-input>
          <!-- <el-select class="input" v-model="form.person_in_charge" placeholder="选择负责人" clearable filterable @change="chargeChange" >
            <el-option v-for="(item,index) in chargeList" :key="index" :label="item.name_zh||name_en" :value="item.user_id">
            </el-option>
          </el-select> -->
        </el-form-item>
        <!-- <div class="fontColor" style="margin-top:-5px">
          说明：指定职位负责人参与招聘，职位负责人可在【职位管理】列表看到该职位，进行筛选简历、安排面试，及修改、下线和删除职位等操作。
        </div> -->
        <el-row style="margin: 10px 0">
          <el-col :span="12">
            <el-form-item :label="$t('email')" prop="contact.email">
              <el-input class="input" size="small" v-model="form.contact.email" :placeholder="$t('enteremailaddress')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('phone')">
              <el-input class="input" size="small" v-model="form.contact.mobile" :placeholder="$t('enterTelephone')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <div class="line"></div>
          <div class="infoTitle">招聘设置</div>
           <el-form-item label="简历同步至邮箱（选填）">
               <el-input class="input" size="small" v-model="email" placeholder="请输入您的邮箱"></el-input>
           </el-form-item> -->
      </el-form>
      <div class="line"></div>
       <div
      class="clearfix drawerFooter">
      <el-button
        type="primary"
        size="small"
        class="fr"
        style="margin-left: 16px; width: 80px"
        :loading="btnloading"
        @click="confimPosition"
        >{{$t('submit')}}</el-button
      >
      <el-button
        size="small"
        class="fr"
        @click="cencelPosition"
        style="width: 80px"
        >{{$t('back')}}</el-button
      >
    </div>
    </div>
    <!-- 职业规范 -->
    <div class="jobDialog"> 
     <el-dialog title="职位发布规范" :visible.sync="publichShow" width="600px" append-to-body>
      <div>
        一、职位名称发布规范</br></br>
        职位名称请勿包含以下信息，包括不限于：</br></br>
        1、含违法违规信息</br></br>
        2、含性别、学历、院校、地区、民族等歧视性信息</br></br>
        3、含认证、融资、招商、培训、推广、挂靠等非招聘或非提供实际就业机会信息</br></br>
        4、含特殊字符、一职多岗、薪资体现、与职位名称无关等不规范信息</br></br>
        二、 职位描述发布规范</br></br>
        职位描述请勿包含以下信息，包括不限于：</br></br>
        1、含违法违规信息</br></br>
        2、含性别、学历、院校、地区、民族等歧视性信息</br></br>
        3、含认证、融资、招商、培训、推广、产品售卖、挂靠等非招聘或非提供实际就业机会信息</br></br>
        4、含收费及潜在收费信息</br></br>
        5、含特殊字符、彩色字体底纹，图片等不规范信息</br></br>
        6、含微信、QQ、手机等联系方式信息</br></br>
        7、除岗位职责、任职条件、工作技能、薪资待遇、汇报关系、业绩指标以外与职位描述无关信息</br></br>
        三、 公司内容简介规范</br></br>
        公司简介请勿包含以下信息，包括不限于：</br></br>
        1、含第三方或违法违规链接</br></br>
        2、含商标、品牌、专利等侵权信息</br></br>
        3、含与企业性质、规模等不符或夸大宣传的信息</br></br>
        4、含特殊字符、彩色字体底纹，图片等不规范信息</br></br>
        5、含微信、QQ、手机等联系方式信息</br></br>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="publichShow = false" size="small">取 消</el-button>
        <el-button type="primary" @click="publichShow = false" size="small">确 定</el-button>
      </span> -->
    </el-dialog></div>

    <!-- 预览简历 -->
    <!-- <div class="previewDialog"> 
       <el-dialog title="预览职位" :visible.sync="previewShow" width="1100px" >
        <previewDialog :positionInfo="form" :aliyunId="aliyunId"></previewDialog>
    </el-dialog></div> -->
  
  </div>
</template>
<script>
import CountrySelect from "~/baseComponents/CountrySelect";
import CityComplete from "~/baseComponents/CityComplete";
import { priorFormat } from "~/basePlugins/filters";

// import previewDialog from "~/components/recruitment/previewDialog";
import {
  positionTypeList,
  wages,
  levelRequire,
  experience,
} from "~bac/utils/enumValue";
export default {
  layout: "memberCenter",
  components: {
    CountrySelect,
    CityComplete,
  },
  props: {
    positionId: {
      type: Number || String,
      default: null,
    },
  },
  watch: {
    positionId() {
      console.log(this.positionId);
      if (this.positionId) {
        this.getList();
      } else {
        this.clearData();
      }
    },
    "form.video_id"() {
      let obj = this.videoList.find((it) => {
        return it.id == this.form.video_id;
      });
      if (obj && obj.id) {
        this.form.video_id = obj.id;
      } else {
        this.form.video_id = "";
      }
      // if (obj) this.aliyunId = obj.aliyun_id;
    },
    "form.salary_lowest"(new_v, old_v) {
      if (old_v === 0) {
        this.form.salary_highest = "";
      }
    },
  },

  data() {
    return {
      form: {
        contact: {
          name: "",
        },
        position_kind: "1", //性质
        name: "", //名称
        position_type: "", //职位类型
        recruit_type: 1, //招聘类型
        address: "",
        level_require: "",
        salary_lowest: "",
        salary_highest: "",
        experience: 2, //经验
        desc: "",
        country: "",
        region: "",
        area_code: "+86",
      },
      contactName: "",
      publichShow: false,
      previewShow: false,
      btnloading: false,
      defaultHolder:
        "请输入岗位职责、任职要求等描述，至少10个字，建议使用以下格式逐条列出：\n\n岗位职责\n\n1….\n2…\n\n任职要求\n\n1….\n2… \n\n补充说明\n\n工作时间、晋升说明、培训情况等",
      recruitList: [
        //招聘类型
        {
          value: 1,
          label: this.$t("schoolZhao"),
        },
        {
          value: 2,
          label: this.$t("socialZhao"),
        },
      ],
      aliyunId: "",
      position_id: "",
      loading: false,
      // defaultlLogo: require("~/assets/img/default-logo.jpg"),
      videoList: [],
      recruitTypeList: positionTypeList, //职位类型
      wagesList: wages, //薪资要求
      educationList: levelRequire, //学历
      experienceList: experience, //工作经验
      chargeList: [],
      selectCharge: {},
      fromRules: {
        name: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        desc: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },

        address: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        country: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        city_name_en: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        city_name_zh: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        level_require: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        salary_lowest: {
          validator: (rule, value, callback) => {
            console.log(value);

            if (value === "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        salary_highest: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        position_type: {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        "contact.name": {
          validator: (rule, value, callback) => {
            if (value == "" || !value) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        "contact.email": {
          required: true,
          message: " ",
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    // 发布或编辑
    confimPosition() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let val = {
            company_id: this.USER_INFO.company_id,
            source: this.PJSource,
          };
          if (this.positionId) {
            val.position_id = this.positionId;
            val.status = this.form.status;
          } else {
            val.creator = this.USER_INFO.id;
          }
          let params = Object.assign(val, this.form);
          params.contact = JSON.stringify(params.contact);
          params.person_in_charge = this.USER_INFO.id;

          if (params.salary_lowest == 0 || params.salary_highest == 0) {
            params.salary_lowest = 0;
            params.salary_highest = 0;
          }
          params.region = priorFormat(
            params.city_name_en,
            params.city_name_zh,
            this.LOCALE
          );
          delete params.city_name_en;
          delete params.city_name_zh;
          this.savePosition(params);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async savePosition(params) {
      let data = await this.$store.dispatch(
        "baseConsole/job_positionSave",
        params
      );
      if (data.success) {
        if (this.position_id) {
          this.$ssTip();
        } else {
          this.$message.success(this.$t('successfully'));
        }
        this.$emit("success");
      } else {
        if (this.position_id) {
          this.$message.error(this.$t('failed'));
        } else {
          this.$message.error(this.$t('failed'));
        }
      }
    },
    async getOptionList() {
      try {
        let result = await this.$store.dispatch(
          "baseConsole/company_getCompanyVideo",
          {
            company_id: this.USER_INFO.company_id,
            nopage: 1,
            // audit_status: 1,
            status: 0,
          }
        );
        if (result.success) {
          this.videoList = result.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getList(id) {
      console.log("getlist");
      this.loading = true;
      let params = {
        user_id: this.USER_INFO.user_id,
        position_id: this.positionId,
      };
      let data = await this.$store.dispatch(
        "baseConsole/job_positiondetail",
        params
      );
      if (data.success) {
        this.loading = false;
        let res = data.data;
        this.form = {
          position_kind: res.position_kind.toString(), //性质
          name: res.name, //名称
          position_type: res.position_type, //职位类型
          recruit_type: res.recruit_type, //招聘类型
          address: res.address,
          level_require: res.level_require,
          salary_lowest: res.salary_lowest,
          salary_highest: res.salary_highest,
          city_name_zh: res.city_name_zh,
          city_name_en: res.city_name_en,
          experience: res.experience, //经验
          desc: res.desc,
          country: res.country,
          video_id: res.video_id,
          region: res.region,
          person_in_charge: res.person_in_charge, //负责人
          contact: JSON.parse(res.contact),
          status: res.status,
        };
        // this.city = data.data.company_region_en
      } else {
        this.loading = false;
      }
    },
    toCompanyVideo() {
      this.$router.push({
        path: "/console/company/video",
      });
    },
    chargeChange(val) {
      let selectCharge = this.chargeList.find((it) => {
        return it.user_id == val;
      });
      this.form.email = selectCharge.email;
      this.form.mobile = selectCharge.mobile;
    },
    clearData() {
      console.log(55555);
      this.$refs["form"].resetFields();
      this.form = {
        contact: {
          name: "",
        },
        position_kind: "1", //性质
        name: "", //名称
        position_type: "", //职位类型
        recruit_type: 1, //招聘类型
        address: "",
        level_require: "",
        salary_lowest: null,
        salary_highest: null,
        experience: 2, //经验
        desc: "",
        country: "",
        region: "",
        area_code: "+86",
      };
    },
    cencelPosition() {
      if (!this.positionId) this.$refs["form"].resetFields();
      this.$emit("cencel");
    },
  },
  mounted() {
    console.log(this.positionId);
    this.getOptionList();
  },
};
</script>
<style lang="less" scoped>
.positionDetail {
  padding-bottom: 100px;
  .infoTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .input {
    width: 300px;
    display: inline-block;
  }
}

.fontColor {
  color: #999;
}

.line {
  padding: 5px 20px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
}

.videoImg {
  width: 30px;
  height: 20px;
  margin: 8px 10px 0 0;
}
.drawerFooter {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 30px;
  width: 100%;
  background: #fff;
}
.mainColor1 {
  color: #3db166;
}
/deep/ .el-form--label-top .el-form-item__label {
  margin-bottom: 8px;
}

/deep/.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
}
.jobDialog {
  /deep/.el-dialog__body {
    height: 630px;
    overflow-y: auto;
  }
}
.previewDialog {
  /deep/.el-dialog__body {
    background: #f0f2f5;
  }
}
</style>
