<template>
  <div class="searchPosition">
    <el-row>
      <el-col :span="4">
        <el-input
          :placeholder="$t('pleaseinputposition')"
          v-model="search.name"
          size="small"
          class="input"
          clearable
        ></el-input>
      </el-col>
      <el-col :span="4">
        <el-select
          class="input"
          size="small"
          v-model="search.position_type"
          :placeholder="$t('positionType')"
          clearable
        >
          <el-option
            v-for="(item, index) in recruitTypeList"
            :key="index"
            :label="item.label_en | priorFormat(item.label_zh, LOCALE)"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select
          class="input"
          size="small"
          v-model="search.status"
          :placeholder="$t('jobStatus')"
          clearable
        >
          <el-option
            v-for="(item, index) in statusList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="3">
        <el-select
          class="input"
          size="small"
          v-model="search.audit_status"
          placeholder="全部审核状态"
          clearable
        >
          <el-option
            v-for="(item, index) in auditList"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col> -->
      <!-- <el-col :span="3">
        <el-select
          class="input"
          size="small"
          v-model="search.person_in_charge"
          placeholder="全部负责人"
          clearable
          filterable
        >
          <el-option
            v-for="(item, index) in chargeList"
            :key="index"
            :label="item.name_zh || name_en"
            :value="item.user_id"
          >
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="12">
        <div class="fr">
          <el-button size="small" type="primary" @click="searchChange">{{
            $t("search")
          }}</el-button>
          <el-button size="small " @click="resetChange">{{
            $t("reset")
          }}</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { positionTypeList } from "~bac/utils/enumValue";
export default {
  data() {
    return {
      search: {
        name: "",
        position_type: "",
        status: "",
        person_in_charge: "",
      },
      chargeList: [],
      recruitTypeList: positionTypeList, //职位类型
      statusList: [
        {
          value: 3,
          label: this.$t("release"),
        },
        {
          value: 4,
          label: this.$t("closed"),
        },
        {
          value: 0,
          label: this.$t("RegisterWait"),
        },

        {
          value: 2,
          label: this.$t("RegisterRefuse"),
        },
      ],
      auditList: [],
    };
  },
  mounted() {
    // this.getCharge();
  },
  methods: {
    // async getCharge() {
    //   let result = await this.$store.dispatch("baseConsole/getUserList", {
    //     //负责人
    //     company_id: this.USER_INFO.company_id,
    //     meeting_id: 60,
    //   });
    //   if (result.success) {
    //     this.chargeList = result.data;
    //   }
    // },
    searchChange() {
      this.$emit("searchChange", this.search);
    },
    resetChange() {
      this.search = {
        name: "",
        position_type: "",
        status: "",
        person_in_charge: "",
      };
    },
  },
};
</script>
<style lang="less" scoped>
.input {
  width: 90%;
}
</style>
